import axios from 'axios';
import React, { useState, useEffect, useRef } from "react";

import jwtDecode from "jwt-decode";

const ProfileForm = () => {
  const isInitialRender = useRef(true);

  const [profile, setProfile] = useState({
    username: "",
    email: "",
    phone: "",
    address: "",
    nationality: "India",
    dob: "",
    profilePic: "https://via.placeholder.com/150",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      const decodedToken = jwtDecode(token);
      console.log(decodedToken); 
      
      const userId = decodedToken?.userId;
      if (!userId) {
        console.error("userId not found in the token payload");
        return;
      }
  
      setUserId(userId); 
  
      setLoading(true);
  
      try {
        const response = await axios.get(`https://ieltscoachingapi.whytap.tech/api/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const data = response.data; 
        console.log("Fetched data:", data);
  
        setProfile({
          username: data.userName || "",
          email: data.Mail || "",
          phone: data.phoneNum || "",
          address: data.address || "",
          nationality: data.nationality || "India",
          dob: data.dob || "",
          //  profilePic: data.profilePic || "https://via.placeholder.com/150",
        });
  
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(err.response?.data?.message || "Failed to fetch user data");
      }
    };
  
    if (isInitialRender.current) {
      fetchUserData();
      isInitialRender.current = false;
    }
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        profilePic: URL.createObjectURL(file),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('username', profile.username);
    formData.append('email', profile.email);
    formData.append('phone', profile.phone);
    formData.append('address', profile.address);
    formData.append('nationality', profile.nationality);
    formData.append('dob', profile.dob);
    
    if (profile.profilePic && profile.profilePic !== "https://via.placeholder.com/150") {
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput.files[0]) {
        formData.append('profilePic', fileInput.files[0]);
      }
    }
  
    try {
      setLoading(true);
      const response = await axios.post(`https://ieltscoachingapi.whytap.tech/api/updated/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Profile updated successfully:', response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.response?.data?.message || "Failed to update profile.");
    }
  };
  
  return (
    <form onSubmit={handleSubmit} className="bg-gray-100 p-6 rounded-lg shadow-lg max-w-2xl mx-auto space-y-6">
      <div className="flex flex-col items-center">
        <img
          src={profile.profilePic}
          alt="Profile"
          className="w-24 h-24 rounded-full object-cover mb-2"
        />
        <label className="bg-blue-500 text-white px-4 py-2 rounded cursor-pointer hover:bg-blue-600">
          Change Picture
          <input
            type="file"
            accept="image/*"
            onChange={handleProfilePicChange}
            className="hidden"
          />
        </label>
      </div>
    <p className="text-red-500">{error}</p>

      {loading && <p className="text-blue-500">Loading...</p>}

      <div className="space-y-4">
        <input
          type="text"
          name="username"
          value={profile.username}
          onChange={handleChange}
          placeholder="Username"
          className="w-full p-2 border rounded text-left"
        />
        <input
          type="email"
          name="email"
          value={profile.email}
          onChange={handleChange}
          placeholder="Email"
          className="w-full p-2 border rounded text-left"
        />
        <input
          type="tel"
          name="phone"
          value={profile.phone}
          onChange={handleChange}
          placeholder="Phone Number"
          className="w-full p-2 border rounded text-left"
        />
        <input
          type="text"
          name="address"
          value={profile.address}
          onChange={handleChange}
          placeholder="Address"
          className="w-full p-2 border rounded text-left"
        />
        <select
          name="nationality"
          value={profile.nationality}
          onChange={handleChange}
          className="w-full p-2 border rounded text-left"
        >
          <option value="India">India</option>
          <option value="United States">United States</option>
          <option value="United Kingdom">United Kingdom</option>
          <option value="Canada">Canada</option>
          <option value="Australia">Australia</option>
        </select>
        <input
          type="date"
          name="dob"
          value={profile.dob}
          onChange={handleChange}
          placeholder="Date of Birth"
          className="w-full p-2 border rounded text-left"
        />
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded mt-4 hover:bg-blue-600">
          Submit
        </button>
      </div>
    </form>
  );
};

export default ProfileForm;
