import React from "react";
import writingPhoto1 from "./photo1.jpg"; // Replace with the correct path
import writingPhoto2 from "./pjoto2.jpg"; // Replace with the correct path
import bannerImage from "./11454725.jpg"; // Keep or replace with your banner image
import { Link } from "react-router-dom";

function Writing() {
    
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center">
      {/* Main Content */}
      <main className="bg-white w-full max-w-5xl p-8 my-6 rounded-lg shadow-md">
        {/* Flex Container for Banner and Title */}
        <div className="flex items-center gap-5 mb-6">
          {/* Banner Image */}
          <img
            src={bannerImage}
            alt="Banner"
            className="w-60 h-60 object-cover mx-10 my-10 rounded-md"
          />

          {/* Title and Description */}
          <div>
            <h1 className="text-2xl font-bold">Quick Practice</h1>
            <p className="text-gray-700 mt-2">
              Improve specific skills by practicing mock question types. Here, you
              will find a variety of writing tasks designed to help you get accustomed to the IELTS writing format.
            </p>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex space-x-4 mb-8 justify-center gap-2">
          <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Book now
          </button>
          <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Practice free
          </button>
        </div>

        {/* Skill Tabs */}
        <div className="flex space-x-6 justify-center mb-8">
            <Link to={'/speaking'}>
          <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Speaking
          </button></Link>
          <Link to={'/reading'}>
          <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Reading
          </button></Link>
          <Link to={'/listening'}>
          <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Listening
          </button></Link>
          <Link to={'/writing'}>
          <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Writing
          </button></Link>
        </div>


        {/* Writing Section */}
        <div className="mt-8">
          <h2 className="text-lg font-semibold mb-2">Writing:</h2>
          <div className="grid grid-cols-2 gap-4">
            {/* First Image Section */}
            <div className="border p-4 rounded-lg shadow-sm flex flex-col items-center cursor-pointer">
             <Link to={'/writingtest'}> <img src={writingPhoto1} alt="Writing Task 1" className="w-32 mb-2" />
              <p className="font-semibold">Write About the Photo</p>
              <p className="text-sm text-gray-600">Creative Writing Task</p></Link>
            </div>

            {/* Second Image Section */}
            <div className="border p-4 rounded-lg shadow-sm flex flex-col items-center cursor-pointer">
              <img src={writingPhoto2} alt="Writing Task 2" className="w-32 mb-2" />
              <p className="font-semibold">Writing Sample</p>
              <p className="text-sm text-gray-600">Practice Essay</p>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <section className="faq-section mt-8">
          <h2 className="text-2xl font-semibold mb-4">Frequently Asked Questions (FAQs)</h2>
          <div className="space-y-4">
            <details className="bg-white shadow rounded p-4">
              <summary className="font-medium">What is the IELTS Writing test?</summary>
              <p className="text-gray-600 mt-2">
                The IELTS Writing test assesses your ability to write responses in English to a variety of prompts,
                including essays and task-based descriptions.
              </p>
            </details>
            <details className="bg-white shadow rounded p-4">
              <summary className="font-medium">How long is the IELTS Writing test?</summary>
              <p className="text-gray-600 mt-2">The test is 60 minutes long, divided into two writing tasks.</p>
            </details>
            <details className="bg-white shadow rounded p-4">
              <summary className="font-medium">Can I use bullet points in the IELTS Writing test?</summary>
              <p className="text-gray-600 mt-2">
                No, answers should be written in full sentences. Using bullet points may affect your score.
              </p>
            </details>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Writing;
