import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post('https://ieltscoachingapi.whytap.tech/api/login', {
        Mail: email,
        password: password,
      });
  
      const { token, message } = response.data;
  
      // Save the token in localStorage
      localStorage.setItem('adminToken', token);
  
      toast.success(message);
      navigate('/admin-route');  // Redirect to the admin dashboard
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data || 'Login failed');
      } else {
        toast.error('An error occurred. Please try again.');
      }
    }
  };
  

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-semibold text-center mb-6">Admin Login</h2>

        <form className="space-y-4" onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Admin Email"
            className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:ring-2 focus:ring-black"
            required
          />

          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:ring-2 focus:ring-black"
            required
          />

          <button
            type="submit"
            className="w-full inline-flex items-center justify-center rounded-lg bg-black py-3 text-sm font-medium text-white focus:ring-2 focus:ring-black"
          >
            Login as Admin
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
