import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Player } from '@lottiefiles/react-lottie-player';
import reg from '../assests/register.json';
import auth from '../assests/reg.png';
import TogglePassword from '../Authentication component/TogglePassword'
function ResetPassword() {
    const [password, setPassword] = useState("");
    const [conformPassword, setconformPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { token } = useParams(); 

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        if (password !== conformPassword) {
            return setError("Passwords do not match");
        }
        if(password.length <=10){
            return setError("Make your Password strong")
        }

        try {
            await axios.post(`https://ieltscoachingapi.whytap.tech/api/request-password?token=${token}`, { 
                newPassword: password, 
                conformPassword: conformPassword 
            });
            navigate("/login"); 
        } catch (err) {
            setError(err.response.data || "Something went wrong.");
        }
    };

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="grid lg:grid-cols-2 gap-6 w-full max-w-4xl h-auto bg-white rounded-lg shadow-lg p-6">
                <div className="flex items-center justify-center">
                    <Player autoplay loop src={reg} style={{ height: '300px', width: '300px' }} />
                </div>

                <div className="flex flex-col items-center justify-center space-y-4 w-full">
                    <h1 className="text-2xl font-semibold text-center">Reset Your Password</h1>
                    <img src={auth} className="h-[100px] w-[100px] rounded-sm" alt="login_img" />
                    {error && <p style={{ color: "red" }}>{error}</p>}

                    <form onSubmit={handleSubmit}>
                        <div>
                            <TogglePassword
                                // className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:ring-2 focus:ring-black"
                                type="password"
                                value={password}
                                placeholder="New Password"
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <TogglePassword
                                className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:ring-2 focus:ring-black my-2"
                                type="password"
                                value={conformPassword}
                                placeholder="Conform Password"

                                onChange={(e) => setconformPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="w-full inline-flex items-center justify-center rounded-lg bg-black py-3 text-sm font-medium text-white focus:ring-2 focus:ring-black">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
