import React from "react";
import photo1 from "./em1.png";
import photo2 from "./em2.png";
import photo3 from "./em3.png";
import photo4 from "./em4.png";
import bannerImage from "./11454725.jpg";
import { Link } from 'react-router-dom'

function IELTSPracticePage() {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center">
      <main className="bg-white w-full max-w-5xl p-8 my-6 rounded-lg shadow-md">
        <div className="flex items-center gap-5 mb-6">
          <img
            src={bannerImage}
            alt="Banner"
            className="w-60 h-60 object-cover mx-10 my-10 rounded-md"
          />

          <div>
            <h1 className="text-2xl font-bold">Quick Practice</h1>
            <p className="text-gray-700 mt-2">
              Improve specific skills by practicing mock questions types. Here, you
              will find a variety of test modules, including multiple-choice
              questions, fill-in-the-blanks, and interactive reading comprehension,
              designed to help you get accustomed to the IELTS test format.
            </p>
          </div>
        </div>

        <div className="flex space-x-4 mb-8 justify-center gap-2">
          <button className=" bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Book now
          </button>
          <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Practice free
          </button>
        </div>

        <div className="flex space-x-6 justify-center mb-8">
          <Link to={'/speaking'}>
            <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
              Speaking
            </button>
          </Link>
          <Link to={'/reading'}>
            <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
              Reading
            </button>
          </Link>
          <Link to={'/listening'}>
            <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
              Listening
            </button>
          </Link>
          <Link to={'/writing'}>
            <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
              Writing
            </button>
          </Link>
        </div>

        {/* Reading Section */}
        <div className="mt-8">
          <h2 className="text-lg font-semibold mb-2">Reading:</h2>
          <div className="grid grid-cols-2 gap-4">
            {/* First Image Section */}
            <div className="border p-4 rounded-lg shadow-sm flex flex-col items-center cursor-pointer">
            <Link  to={'/quizintro'}>
              <img src={photo1} alt="Reading 1" className="w-32 mb-2" />
              <p className="font-semibold">Read and Select</p>
              
              <p className="text-sm text-gray-600">Reading comprehension</p></Link> 
            </div>

            {/* Second Image Section */}
            <div className="border p-4 rounded-lg shadow-sm flex flex-col items-center cursor-pointer">
              <img src={photo2} alt="Reading 2" className="w-32 mb-2" />
              <p className="font-semibold">Read and Complete</p>
              <p className="text-sm text-gray-600">Reading comprehension MCQ</p>
            </div>

            {/* Third Image Section */}
            <div className="border p-4 rounded-lg shadow-sm flex flex-col items-center cursor-pointer">
              <img src={photo3} alt="Reading 3" className="w-32 mb-2" />
              <p className="font-semibold">Read and Select</p>
              <p className="text-sm text-gray-600">Reading comprehension</p>
            </div>

            {/* Fourth Image Section */}
            <div className="border p-4 rounded-lg shadow-sm flex flex-col items-center cursor-pointer">
              <img src={photo4} alt="Reading 4" className="w-32 mb-2" />
              <p className="font-semibold">Read and Complete</p>
              <p className="text-sm text-gray-600">Reading comprehension MCQ</p>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="mt-8">
          <h2 className="text-lg font-semibold mb-2">Frequently Asked Questions (FAQs)</h2>
          <div className="space-y-4">
            <details className="border-b py-2">
              <summary className="font-medium">What is the IELTS Reading test?</summary>
              <p className="text-gray-600 mt-2">
                The IELTS Reading test is designed to assess a candidate’s
                reading skills through various question types like multiple-choice,
                fill-in-the-blanks, and more.
              </p>
            </details>
            <details className="border-b py-2">
              <summary className="font-medium">How long is the IELTS Reading test?</summary>
              <p className="text-gray-600 mt-2">
                The IELTS Reading test lasts for 60 minutes, covering three sections.
              </p>
            </details>
            <details className="border-b py-2">
              <summary className="font-medium">What types of questions are included?</summary>
              <p className="text-gray-600 mt-2">
                Question types include multiple-choice, matching headings, and more.
              </p>
            </details>
            <details className="border-b py-2">
              <summary className="font-medium">Can I use a dictionary during the test?</summary>
              <p className="text-gray-600 mt-2">
                No, dictionaries are not allowed during the IELTS Reading test.
              </p>
            </details>
          </div>
        </div>
      </main>
    </div>
  );
}

export default IELTSPracticePage;
