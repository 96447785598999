import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="bg-gradient-to-r from-indigo-900 to-indigo-600 text-white py-4">
  <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
    <div>
      <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
      <ul className="space-y-2">
        <li><Link to="/" className="hover:underline">Home</Link></li>
        <li><Link to="/privacy-policy" className="hover:underline">Privacy Policy</Link></li>
        <li><Link to="/cookie-policy" className="hover:underline">Cookie Policy</Link></li>
      </ul>
    </div>
    <div>
      <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
      <p>Email: info@leetscholar.com</p>
      <p>Phone: +123-456-7890</p>
    </div>
    <div>
      <h4 className="text-lg font-semibold mb-4">Stay Connected</h4>
      <div className="flex space-x-4">
        <a href="https://www.youtube.com" className="transition-transform hover:scale-125"><img src={require('./Assets/youtube_(2).png')} alt="YouTube" className="w-8 h-8" /></a>
        <a href="https://www.facebook.com" className="transition-transform hover:scale-125"><img src={require('./Assets/facebook_(2).png')} alt="Facebook" className="w-8 h-8" /></a>
        <a href="https://www.instagram.com" className="transition-transform hover:scale-125"><img src={require('./Assets/social.png')} alt="Instagram" className="w-8 h-8" /></a>
      </div>
    </div>
  </div>
  <p className="text-center text-xs mt-8 text-gray-300">© 2024 IELTS Preparation. All rights reserved.</p>
</footer>

);

export default Footer;

