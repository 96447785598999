import React, { useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import reg from '../assests/register.json'
import auth from '../assests/reg.png'
import { Link} from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';



function ForgotPassword() {
    const [Mail, setMail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setErrormsg] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");  
        setErrormsg("");
        try {
          const res=  await axios.post("https://ieltscoachingapi.whytap.tech/api/request-reset", { Mail });
            setMessage("Please check your email for the reset link.");
            toast.success(res.data)
        } catch (error) {
            // setMessage(error.response?.data?.message || "Something went wrong.");
            if(error.response){
              const  errorMessage = error.response?.data || "Something went wrong."
                toast.error(errorMessage)
            }
        }
    };

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="grid lg:grid-cols-2 gap-6 w-full max-w-4xl h-auto bg-white rounded-lg shadow-lg p-6">


                <div className="flex items-center justify-center">
                    <Player
                        autoplay
                        loop
                        src={reg}
                        style={{ height: '300px', width: '300px' }}>
                    </Player>
                </div>

                <div className="flex flex-col items-center justify-center space-y-4 w-full">

                    <h1 className="text-2xl font-semibold text-center">Reset your password</h1>

                    <img src={auth} className="h-[100px] w-[100px] rounded-sm" alt="login_img" />

                    <p style={{ color: "red" }}>{error}</p>
                    <p style={{ color: "green" }}>{message}</p>

                    <form onSubmit={handleSubmit}>
                        <input
                            className=" w-full rounded-lg border border-gray-300 px-3 py-2  my-2  outline-none
                                      focus:ring-2 focus:ring-black "
                            type="email"
                            value={Mail}
                            onChange={(e) => setMail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                        <button className="w-full inline-flex items-center justify-center rounded-lg bg-black py-3 text-sm font-medium text-white focus:ring-2 focus:ring-black"
                            type="submit">Send Reset Link</button>
                        <div className="flex justify-center items-center text-center text-sm text-slate-600">

                            <Link className="font-medium text-[#4285f4] ml-1 m-4" to={'/login'}> Go back</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
}

export default ForgotPassword;
