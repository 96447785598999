import React from 'react'
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate()
    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/login");
      };
  return (
    <a onClick={handleLogout}>Logout</a>
  )
}

export default Logout