import React, { useState } from "react";
import writingImage from "../assests/onlinetest.png";
import axios from "axios"; // Import axios for making the API request

function WritingTest() {
  const [text, setText] = useState("");
  const maxCharacters = 500;

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = async () => {
    // Make an API request to store the response
    try {
      await axios.post("http://localhost:5000/api/writing-test", {
        response: text,
      });
      alert("Your response has been submitted!");
      setText(""); // Clear the text after submission
    } catch (error) {
      console.error("Error submitting response:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center p-6">
      <div className="bg-white w-full max-w-3xl p-6 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4 text-center">Writing Test</h1>

        <div className="mb-6">
          <img
            src={writingImage}
            alt="Writing Topic"
            className="w-full h-64 object-cover rounded-md"
          />
        </div>

        <textarea
          value={text}
          onChange={handleTextChange}
          className="w-full h-40 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 resize-none"
          placeholder="Type your response here..."
          maxLength={maxCharacters}
          data-gramm="false" // Disable Grammarly here
        ></textarea>

        <div className="text-right text-gray-600 mt-2">
          Characters: {text.length} / {maxCharacters}
        </div>

        <button
          onClick={handleSubmit}
          disabled={text.length === 0}
          className={`w-full mt-4 py-2 rounded-md font-semibold text-white ${
            text.length === 0 ? "bg-gray-400 cursor-not-allowed" : "bg-purple-600 hover:bg-purple-700"
          }`}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default WritingTest;
