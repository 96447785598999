import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../assests/logout.json'; 
import { Link } from 'react-router-dom';

const AnimatedBanner = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gradient-bg bg-[length:200%_200%] animate-bg-gradient text-white p-6 overflow-hidden">
      <h3 className="text-2xl font-semibold mt-10 mb-4 text-center">Oh no! You've logged out</h3>

      <div className="w-full max-w-md mb-8">
        <Lottie animationData={animationData} loop={true} />
      </div>

      <Link 
        to="/login" 
        className="w-[250px] h-[50px] inline-flex items-center justify-center rounded-lg bg-black py-3 text-sm font-medium text-white focus:ring-2 focus:ring-black text-3xl"
      >
        Login
      </Link>
    </div>
  );
};

export default AnimatedBanner;
