import './App.css';
import Privaterouter from './Privateroute/Privateroute'
import AuthProvider from '../src/ContextComponent/AuthContextcomp'
import Register from './Authentication component/Register';
import { Route, Routes } from 'react-router-dom';
import Login from './Authentication component/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResetPassword from './Authentication component/ResetPassword';
import ForgotPassword from './Authentication component/ForgotPassword';
import TestComponent from './testComponent/TestComponent';
import { useEffect, useState } from 'react';
import QuizIntro from './testComponent/TestFirstpg';
import Footer from './component/Footer'
import Navbar from './component/Navbar'
import Info from './component/Info'
import PrivacyPolicy from './component/Privacy'
import CookiePolicy from './component/Cookie'
import ContactUs from './component/Contact';
import Booking from './component/Booking'
import Payment from './component/Payment'
import Assessment from './component/Assessment'
import Home from './component/Home'
import Logout from './Authentication component/Logout';
import CourseFrontPg from './Course component/CourseFrontpg'
import BegCoursePDF from './Course component/BegCoursePDF';
import Listening from './skill modules/listining/lisining'
import ListeningTest from './testComponent/Listeningtest'
import IELTSPracticePage from './skill modules/reading/reading'
import Writing from './skill modules/writing/writing'
import Writingtest from './testComponent/Writingtest';
import Practicshome from './skill modules/practicshome/practicshome'
import Speaking from './skill modules/speaking/speaking'
import Authenticationfailed from './Authentication component/AuthenticationFailedpg'
import UserprofileRoutes from './UserprofileRoutes';
import ListenTestpg from './Listening module test/ListenTestpg';
import ListenStartpg from './Listening module test/Listenstartpg';
import FindMore from './component/Findmore';
import Userhistory from './testComponent/Userhistory'
import AdminLogin from './Admin Authentication/AdminLogin';
import AdminRegister from './Admin Authentication/AdminRegister';
import AdminRoute from './AdminRoute';
function App() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const savedToken = localStorage.getItem("token");
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);

  const handleToken = (token) => {
    localStorage.setItem("token", token);
    setToken(token);
  };
  return (
    <div className="App flex flex-col min-h-screen">
      <AuthProvider>
        <Navbar />
        <main className="flex-grow mt-[4rem]">

          <Routes>
            {/* public routes */}
            <Route path="/" element={<Home />} />
            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login setToken={handleToken} />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/forgotpassword' element={<ForgotPassword />} />
            <Route path="/Contact Us" element={<ContactUs />} />
            <Route path="/Information" element={<Info />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/Assessment test" element={<Assessment />} />
            <Route path="/course" element={<CourseFrontPg />} />
            <Route path='/practicstest' element={<Practicshome />} />
            <Route path='/home' element={<Home />} />
            <Route path='/fail' element={<Authenticationfailed />} />
            <Route path = '/findmore' element={<FindMore/>}/>
            
            <Route path='/admin-login' element={<AdminLogin/>}/>
            <Route path='/admin-register' element={<AdminRegister/>}/>
           
            
            {/* Private routes */}
            <Route element={<Privaterouter />} >

              <Route path='/start-test' element={<TestComponent token={token} />} />
              <Route path='/Quizintro' element={<QuizIntro />} />
              <Route path="/book/slot/beginner" element={<Booking />} />
              <Route path="/book/onspot/beginner" element={<Payment />} />
              <Route path="/book/slot/intermediate" element={<Booking />} />
              <Route path="/book/onspot/intermediate" element={<Payment />} />
              <Route path="/book/slot/expert" element={<Booking />} />
              <Route path="/book/onspot/expert" element={<Payment />} />
              <Route path='/course/beg/studyMaterials' element={<BegCoursePDF />} />
              <Route path="/reading" element={<IELTSPracticePage />} />
              <Route path="/speaking" element={<Speaking />} />
              <Route path="/listening" element={<Listening />} />
              <Route path='/listeningtest' element={<ListeningTest />} />
              <Route path="/Writing" element={<Writing />} />
              <Route path="/writingtest" element={<Writingtest />} />
              <Route path='/start-listentest' element={<ListenTestpg />} />
              <Route path='/listentestintro' element={<ListenStartpg />} />
              <Route path="/userroutes/*" element={<UserprofileRoutes />} />
              <Route path='/payemnt' element={<Payment/>}/>
              <Route path = '/user-history' element={<Userhistory/>}/>
            </Route>
          </Routes>
          <ToastContainer position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        </main>
        
        <Footer />
      </AuthProvider>
    </div>
  );
}

export default App;
