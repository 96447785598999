import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../ContextComponent/AuthContextcomp';

const Navbar = ({ toggleSidebar }) => {
  const [isCourseDropdownOpen, setIsCourseDropdownOpen] = useState(false);
  const [isTestDropdownOpen, setIsTestDropdownOpen] = useState(false);

  const { isLoggedIn, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const toggleCourseDropdown = () => {
    setIsCourseDropdownOpen(!isCourseDropdownOpen);
    setIsTestDropdownOpen(false);
  };

  const toggleTestDropdown = () => {
    setIsTestDropdownOpen(!isTestDropdownOpen);
    setIsCourseDropdownOpen(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <nav className="bg-indigo-900 text-gray-50 p-2 fixed top-0 w-full z-20 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="cursor-pointer">
          <div className="flex flex-col items-start">
            <h1 className="text-2xl font-bold px-11">IELTS - Leetscholar</h1>
            <p className="text-sm font-light px-11">Preparation For Life</p>
          </div>
        </Link>

        <div className="flex space-x-8">
          <div className="relative">
            <Link to="/course">
              <button
                onClick={toggleCourseDropdown}
                className="text-gray-100 hover:text-blue-300 font-medium flex items-center focus:outline-none"
              >
                IELTS Course
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
            </Link>
            {isCourseDropdownOpen && (
              <div className="absolute bg-slate-800 text-gray-100 rounded-md shadow-lg mt-2 w-48 z-10">
                <Link
                  to="/course/beg/studyMaterials"
                  className="block px-4 py-2 hover:bg-indigo-700"
                  onClick={() => setIsCourseDropdownOpen(false)}
                >
                  Beginner
                </Link>
                <Link
                  to="/course/inter/studyMaterials"
                  className="block px-4 py-2 hover:bg-indigo-700"
                  onClick={() => setIsCourseDropdownOpen(false)}
                >
                  Intermediate
                </Link>
                <Link
                  to="/course/export/studyMaterials"
                  className="block px-4 py-2 hover:bg-indigo-700"
                  onClick={() => setIsCourseDropdownOpen(false)}
                >
                  Expert
                </Link>
              </div>
            )}
          </div>

          {/* IELTS Test Dropdown */}
          <div className="relative">
            <button
              onClick={toggleTestDropdown}
              className="text-gray-100 hover:text-blue-300 font-medium flex items-center focus:outline-none"
            >
              IELTS Test
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {isTestDropdownOpen && (
              <div className="absolute bg-gray-800 text-gray-100 rounded-md shadow-lg mt-2 w-48 z-10">
                <Link
                  to="/practicstest"
                  className="block px-4 py-2 hover:bg-indigo-700"
                  onClick={() => setIsTestDropdownOpen(false)}
                >
                  Practice Tests
                </Link>
                <Link
                  to="/Assessment test"
                  className="block px-4 py-2 hover:bg-indigo-700"
                  onClick={() => setIsTestDropdownOpen(false)}
                >
                  Assessment Test
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="ml-auto flex space-x-7 text-gray-100 font-semibold">
          <Link to="/Information" className="hover:text-blue-100 hover:underline">Information</Link>
          <Link to="/Contact Us" className="hover:text-blue-100 hover:underline">Contact Us</Link>

          {isLoggedIn ? (
            <>
              <Link to="/userroutes">
                <img
                  src={require('./Assets/user.png')}
                  alt="User Profile"
                  className="w-7 h-7 cursor-pointer"
                />
              </Link>
              <button onClick={handleLogout} className="hover:text-blue-100 hover:underline">Logout</button>
            </>
          ) : (
            <>
              <Link to="/login" className="hover:underline hover:text-blue-100">Login</Link>
              <Link to="/register" className="hover:text-blue-100 hover:underline">Sign Up</Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;