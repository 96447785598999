import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import  {AuthContext}  from '../ContextComponent/AuthContextcomp';

const PrivateRoute = () => {
  const { isLoggedIn } = useContext(AuthContext);
  console.log("AuthContext:", AuthContext);
  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;

// import React, { useContext } from 'react';
// import { Navigate, Outlet } from 'react-router-dom';
// import { AuthContext } from '../ContextComponent/AuthContextcomp';

// const PrivateRoute = ({ requiredRole }) => {
//   const { isLoggedIn, role } = useContext(AuthContext);

//   // If not logged in or role does not match the required role, redirect to login
//   if (!isLoggedIn) {
//     return <Navigate to="/login" />;
//   }

//   if (requiredRole && role !== requiredRole) {
//     return <Navigate to="/" />;  // Redirect to home or other page if the user does not have required role
//   }

//   return <Outlet />;
// };

// export default PrivateRoute;








































// import React, { useContext } from 'react';
// import { Navigate, Outlet } from 'react-router-dom';
// import { AuthContext } from '../ContextComponent/AuthContextcomp';

// const PrivateRoute = () => {
//   const { isLoggedIn, isPaidUser } = useContext(AuthContext);

//   // Redirect to login if not logged in
//   if (!isLoggedIn) {
//     return <Navigate to="/login" />;
//   }

//   // Redirect to payment if the user hasn't paid yet
//   if (!isPaidUser) {
//     return <Navigate to="/payment" />;
//   }

//   // Allow access if logged in and paid
//   return <Outlet />;
// };

// export default PrivateRoute;
