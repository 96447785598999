import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import Sidebar from './User profile dashboard/Sidebar';
import ProfileForm from './User profile dashboard/Userprofile';
import FeedbackForm from './User profile dashboard/Feedbackform';
import Userhistory from './testComponent/Userhistory';



const UserprofileLayout = () => (
  <div className="flex">
   
    <Sidebar/>
   
    <main className="flex-grow bg-gray-50 m-7">
      
      <Outlet />
    </main>
  </div>
);





const UserprofileRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UserprofileLayout />}>
        <Route path="profile" element={<ProfileForm />} />
        <Route path="feedback" element={<FeedbackForm />} />
        <Route path="user-history" element={<Userhistory />} />
      </Route>
    </Routes>
  );
};

export default UserprofileRoutes;



