import React, { useEffect } from 'react';

const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="max-w-5xl mx-auto py-16 px-6 sm:px-12 lg:px-20 text-gray-800">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-8 text-center">
          Cookie Policy for IELTS-Leetscholar
        </h1>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-600">1. What Are Cookies?</h2>
          <p>
            Cookies are small data files stored on your device when you visit our website. They allow 
            us to remember your preferences and improve your experience.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-600">2. Types of Cookies We Use</h2>
          <ul className="list-disc pl-6 space-y-4">
            <li>
              <strong>Essential Cookies:</strong> Necessary for the website's core functionality.
            </li>
            <li>
              <strong>Performance Cookies:</strong> Help us analyze user interactions and improve the site.
            </li>
            <li>
              <strong>Functional Cookies:</strong> Remember preferences like language and region.
            </li>
            <li>
              <strong>Advertising Cookies:</strong> Deliver relevant ads based on browsing behavior.
            </li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-600">3. Managing Your Preferences</h2>
          <p>
            Adjust your browser settings to block or delete cookies. Note that disabling cookies 
            may affect website functionality.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-600">4. Third-Party Cookies</h2>
          <p>
            Our site may use third-party cookies (e.g., Google Analytics). Review their privacy policies 
            for more details.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-600">5. Contact Us</h2>
          <p>
            For inquiries, email us at 
            <a href="mailto:ieltsleetscholar@gmail.com" className="text-blue-500 ml-1">
              ieltsleetscholar@gmail.com
            </a>.
          </p>
        </section>
      </div>
    </div>
  );
};

export default CookiePolicy;
