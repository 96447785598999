import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const TogglePassword = ({ placeholder, name, value, onChange }) => {
  const [showPassword, setShowPassword] = useState()
  const TogglepswdVisibility = () => {
    setShowPassword(!showPassword)
  }
  return (
    <div className='relative w-full max-w-[400px]'>
      <input type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange} className='w-full rounded-lg border border-gray-300 px-3 py-2  my-2  outline-none
                                      focus:ring-2 focus:ring-black '
      ></input>
      <span className='absolute right-[20px] top-[40%]-translate-x-[50%]  bottom-4 cursor-pointer ' onClick={TogglepswdVisibility}>
        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />

      </span>
    </div>
  )
}

export default TogglePassword