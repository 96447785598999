import React from "react";
import bannerImage from "./11454725.jpg";
import {Link} from 'react-router-dom'


const Practicshome = () => {
  return (
    
    <div className="bg-gray-100 min-h-screen flex flex-col items-center">
    <main className="bg-white w-full max-w-5xl p-8 my-6 rounded-lg shadow-md">
      <div className="flex items-center gap-5 mb-6">
        <img
          src={bannerImage}
          alt="Banner"
          className="w-60 h-60 object-cover mx-10 my-10 rounded-md"
        />

        <div>
          <h1 className="text-2xl font-bold">Quick Practice</h1>
          <p className="text-gray-700 mt-2">
            Improve specific skills by practicing mock questions types. Here, you
            will find a variety of test modules, including multiple-choice
            questions, fill-in-the-blanks, and interactive reading comprehension,
            designed to help you get accustomed to the IELTS test format.
          </p>
        </div>
      </div>

    
       <div className="flex space-x-4 mb-8 justify-center gap-2
      ">
        <button className=" bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
          Book now
        </button>
        <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
          Practice free
        </button>
      </div>

      <div className="flex space-x-6 justify-center mb-8">
          <Link to={'/speaking'}>
        <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
          Speaking
        </button></Link>
        <Link to={'/reading'}>
        <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
          Reading
        </button></Link>
        <Link to={'/listening'}>
        <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
          Listening
        </button></Link>
        <Link to={'/writing'}>
        <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
          Writing
        </button></Link>
      </div>



    
    </main>
  </div>
);
}

export default Practicshome
