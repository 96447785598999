import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaUser, FaCommentAlt, FaHistory } from 'react-icons/fa';

function Sidebar() {
  return (
    <div className="flex min-h-screen">
      <div className="bg-indigo-800 text-white w-64 min-h-screen flex flex-col items-center p-6 shadow-lg">
    
        <div className="flex flex-col items-center mb-10">
          <img
            src="https://via.placeholder.com/80"
            alt="Profile"
            className="w-20 h-20 rounded-full mb-4 border-2 border-white shadow-md"
          />
          <h1 className="text-lg font-bold">John Doe</h1>
          <p className="text-sm text-indigo-200">IELTS Enthusiast</p>
        </div>

     
        <nav className="flex flex-col w-full space-y-4">
          <NavLink
            to="/userroutes/profile"
            className={({ isActive }) =>
              `flex items-center px-4 py-2 rounded-lg text-sm transition-colors ${
                isActive ? 'bg-indigo-600 text-white' : 'hover:bg-indigo-700'
              }`
            }
          >
            <FaUser className="mr-2" /> Profile
          </NavLink>
          <NavLink
            to="/userroutes/feedback"
            className={({ isActive }) =>
              `flex items-center px-4 py-2 rounded-lg text-sm transition-colors ${
                isActive ? 'bg-indigo-600 text-white' : 'hover:bg-indigo-700'
              }`
            }
          >
            <FaCommentAlt className="mr-2" /> Feedback
          </NavLink>
          <NavLink
            to="/userroutes/user-history"
            className={({ isActive }) =>
              `flex items-center px-4 py-2 rounded-lg text-sm transition-colors ${
                isActive ? 'bg-indigo-600 text-white' : 'hover:bg-indigo-700'
              }`
            }
          >
            <FaHistory className="mr-2" /> History
          </NavLink>
        </nav>

        <div className="mt-auto pt-10">
          <p className="text-sm text-indigo-300">© 2024 Leetscholar</p>
        </div>
      </div>

     
    </div>
  );
}

export default Sidebar;
