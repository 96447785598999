import React, { useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import reg from '../assests/register.json'
import auth from '../assests/reg.png'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import  axios  from 'axios';
import TogglePassword from '../Authentication component/TogglePassword'
const Login = () => {
  const [error,setErrormsg]= useState("")
  const navigate= useNavigate()
  const initialState={
    Mail:'',
    password:''
  }
  const [formData,setFormData]= useState(initialState)
  const handleSubmit= async(e)=>{
    e.preventDefault()
    setErrormsg("")
    try {
     
      const res= await axios.post("https://ieltscoachingapi.whytap.tech/api/login",formData)
  
        if(res.status===200){
          const { token } = res.data;
          localStorage.setItem("token", token);

          navigate('/home')
          setFormData(initialState)
        }
        toast.success(res.data.message) 
     
    } catch (error) {
      console.error("Error response:", error);

      if (error.response) {
        const errorMessage = error.response.data || "An unexpected error occurred.";
    
       
        toast.error(errorMessage);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
  
      }
  
    }}
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="grid lg:grid-cols-2 gap-6 w-full max-w-4xl h-auto bg-white rounded-lg shadow-lg p-6">

       
        <div className="flex items-center justify-center">
          <Player
            autoplay
            loop
            src={reg}
            style={{ height: '300px', width: '300px' }}>
          </Player>
        </div>

        <div className="flex flex-col items-center justify-center space-y-4 w-full">
          
          <h1 className="text-2xl font-semibold text-center">Login to your account</h1>
          <h6 className="text-2xl font-semibold  text-red-500 text-center">{error}</h6>

          <img src={auth} className="h-[100px] w-[100px] rounded-sm" alt="login_img" />

          <form className="w-3/4 flex flex-col gap-3 "onSubmit={handleSubmit} onReset={()=>setFormData(initialState)}>
           

            <input
              name="Mail"
              type="email"
              required
              className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:ring-2 focus:ring-black"
              placeholder="Email Id"
              value={formData.Mail} onChange={(e)=>{setFormData({...formData, Mail:e.target.value})}              }
            />

           
            <TogglePassword
              name="password"
              required
              className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:ring-2 focus:ring-black"
              placeholder="Password"
              value={formData.password} onChange={(e)=>{setFormData({...formData, password:e.target.value})}              }

            />

           

            <button
              type="submit"
              className="w-full inline-flex items-center justify-center rounded-lg bg-black py-3 text-sm font-medium text-white focus:ring-2 focus:ring-black"
            >
              Continue
            </button>
          </form>

          <div className="flex justify-center items-center text-center text-sm text-slate-600">
            Don't have an account?
            <Link className="font-medium text-[#4285f4] ml-1" to={'/register'}>Sign up</Link>
          </div>
          <div className='m-1'><Link className='text-[#515357] ml-1 gap-2' to={'/forgotpassword'}>forgot password?</Link></div>

        </div>
      </div>
    </div>
  );
}

export default Login;
