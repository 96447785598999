import React, { useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import reg from '../assests/register.json'
import auth from '../assests/reg.png'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import TogglePassword from './TogglePassword';

const Register = () => {
  const [error, setErrormsg] = useState("");
  const navigate = useNavigate()
  const initialState = {
    userName: "",
    Mail: "",
    phoneNum: "",
    password: "",
    conformPassword: "",
  }
  const [formData, setFormData] = useState(initialState)

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(formData)
    setErrormsg("")
    if (formData.password !== formData.conformPassword) {
      setErrormsg("Password doesn't Match")
    }
    if (formData.password.length <= 8) {
      return setErrormsg("Make your password strong")
    }
    if (formData.phoneNum.length !== 10) {
        setErrormsg("please enter the valid 10 digit Numbers")
    }
    const { userName, Mail, phoneNum, password } = formData;

    try {

     const res=  await axios
      .post("https://ieltscoachingapi.whytap.tech/api/register", { userName, Mail, phoneNum, password })
        if (res.status === 200) {
          navigate('/login')
          setFormData(initialState)
          toast.success(res.data.message)
        }

    } catch (error) {
       console.error("Error response:", error);

    if (error.response) {
      const errorMessage = error.response.data || "An unexpected error occurred.";
  
     
      toast.error(errorMessage);
    } else {
      toast.error("An unexpected error occurred. Please try again.");

    }

  }}
  return (


    <div className=" flex items-center justify-center h-screen">

      <div className='grid lg:grid-cols-2 gap-6 w-full max-w-4xl h-auto  bg-white rounded-lg shadow-lg p-6'>

        <div className='flex items-center justify-center' >
          <Player
            autoplay
            loop
            src={reg}
            style={{ height: '300px', width: '300px' }}
          >
          </Player>
        </div>

        <div className='flex justify-center gap-2 items-center flex-col'>
          <h1 className=" w-3/4 text-2xl  mx-10 font-semibold ">Register to your account</h1>

          <img src={auth} className='  h-[100px] w-[100px] rounded-sm ' alt='login_img' ></img>
          <p className='text-red-700 text-sm mb-2'>{error}</p>
          <form className='w-3/4 flex flex-col gap-2 ' onSubmit={handleSubmit}  onReset={() => setFormData(initialState)}>
            <input name="userName" type="text" required
              className=" w-full rounded-lg border border-gray-300 px-3 py-2  my-2  outline-none
                                      focus:ring-2 focus:ring-black "
              placeholder="UserName" value={formData.userName} onChange={(e) => setFormData({ ...formData, userName: e.target.value })} />

            <input name="Mail" type="email" required
              className=" w-full rounded-lg border border-gray-300 px-3 py-2  my-2  outline-none
                                      focus:ring-2 focus:ring-black "
              placeholder="Email Id" value={formData.Mail} onChange={(e) => setFormData({ ...formData, Mail: e.target.value })} />

            <input name="phoneNum" type="Number" required
              className=" w-full rounded-lg border border-gray-300 px-3 py-2  my-2  outline-none
                                      focus:ring-2 focus:ring-black "
              placeholder="Phone Number" value={formData.phoneNum} onChange={(e) => setFormData({ ...formData, phoneNum: e.target.value })} />

            <TogglePassword name="password"  required
              // className=" w-full rounded-lg border border-gray-300 px-3 py-2  my-2  outline-none focus:ring-2 focus:ring-black "
              placeholder="Password" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} />

            <TogglePassword name="conformPassword" type="password" required
              // className=" w-full rounded-lg border border-gray-300 px-3 py-2  my-2  outline-none                                      focus:ring-2 focus:ring-black "
              placeholder="Conform Password" value={formData.conformPassword} onChange={(e) => setFormData({ ...formData, conformPassword: e.target.value })} />

            <button type="submit"
              className=" w-full inline-flex  items-center justify-center rounded-lg bg-black p-2 py-3 text-sm font-medium text-white outline-none focus:ring-2 focus:ring-black focus:ring-offset-1 disabled:bg-gray-400" onsubmit={handleSubmit}>
              Continue
            </button>
          </form>

          <div className="flex justify-center items-center text-center text-sm text-slate-600">
            Don't have an account?
            <Link className="font-medium text-[#4285f4] ml-1" to={'/login'}>Sign in</Link>
          </div>


        </div>

      </div>
    </div>


  );
}

export default Register
