import React from 'react';
import course1 from '../assests/course1.png';

const BegCoursePDF = () => {
    const pdfLinks = {
        listening: '/pdf/listening.pdf',
        reading: '/pdf/reading.pdf',
        speaking: '/pdf/speaking.pdf',
        writing: '/pdf/writing.pdf'
    };

    return (
        <div className="rounded-lg m-20 ml-28 w-full max-w-3xl h-auto">
            <div className="bg-white p-10 rounded-lg shadow-lg w-[70vw] h-auto text-center">
                <h1 className="text-2xl font-bold mb-10 mt-3">
                    Beginner Level Course - PDF Format Study Materials
                </h1>
                <div className="mb-6 flex items-center">
                    <img src={course1} alt="book illustration" className="h-[300px] w-[300px] mr-6" />
                    <p className="text-gray-600">
                        Beginner Level IELTS Course: Enhance Your Studies for Your Upcoming Test.
                        Prepare for your upcoming IELTS test with our beginner-level course, designed
                        to boost your study skills and confidence. Utilize the study materials provided
                        to make the most of your learning experience. Dive in and get the best out of
                        these resources to improve your performance and achieve your desired scores.
                    </p>
                </div>
                <div className="mb-6">
                    <p className="text-xl text-slate-900 mb-4">
                        Click the links below to view the PDF for each skill set:
                    </p>
                    <ul className="list-none space-y-6 text-left">
                        {/* Listening Module */}
                        <li>
                            <h2 className="text-lg font-semibold">IELTS Listening Module</h2>
                            <p className="text-gray-700">
                                <strong>Overview:</strong> The IELTS Listening test is designed to evaluate how well you understand spoken English through a variety of audio clips, ranging from monologues to conversations.
                            </p>
                            <h3 className="font-medium">Key Sections:</h3>
                            <ul className="list-disc list-inside text-gray-600">
                                <li>Section 1: Conversation between two people in an everyday context (e.g., booking a hotel).</li>
                                <li>Section 2: Monologue related to a social situation (e.g., a speech about local facilities).</li>
                                <li>Section 3: Group conversation (e.g., students discussing a project).</li>
                                <li>Section 4: Academic monologue (e.g., a university lecture).</li>
                            </ul>
                            <h3 className="font-medium">Preparation Tips:</h3>
                            <ul className="list-disc list-inside text-gray-600">
                                <li>Practice active listening by engaging with various English audio content such as podcasts, news, and movies with subtitles.</li>
                                <li>Identify keywords to anticipate upcoming information.</li>
                                <li>Practice listening tests with headphones in a quiet environment to simulate test conditions.</li>
                                <li>Familiarize yourself with different English accents as IELTS uses British, Australian, and North American accents.</li>
                            </ul>
                            <a
                                href={pdfLinks.listening}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline"
                            >
                                Listening PDF
                            </a>
                        </li>

                        {/* Reading Module */}
                        <li>
                            <h2 className="text-lg font-semibold">IELTS Reading Module</h2>
                            <p className="text-gray-700">
                                <strong>Overview:</strong> The IELTS Reading test assesses your reading skills, including understanding main ideas, details, inferences, and vocabulary.
                            </p>
                            <h3 className="font-medium">Key Sections:</h3>
                            <ul className="list-disc list-inside text-gray-600">
                                <li>Academic Reading: Consists of three long passages related to academic subjects for candidates taking the Academic IELTS test.</li>
                                <li>General Training Reading: Contains extracts from books, magazines, advertisements, and instructions for practical use.</li>
                            </ul>
                            <h3 className="font-medium">Preparation Tips:</h3>
                            <ul className="list-disc list-inside text-gray-600">
                                <li>Practice skimming for main ideas and scanning for specific information.</li>
                                <li>Improve vocabulary by noting unfamiliar words and using context to guess meanings.</li>
                                <li>Manage time by allocating a set duration for each section to avoid lingering too long on any one passage.</li>
                                <li>Use IELTS reading practice materials to familiarize yourself with question formats.</li>
                            </ul>
                            <a
                                href={pdfLinks.reading}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline"
                            >
                                Reading PDF
                            </a>
                        </li>

                        {/* Speaking Module */}
                        <li>
                            <h2 className="text-lg font-semibold">IELTS Speaking Module</h2>
                            <p className="text-gray-700">
                                <strong>Overview:</strong> The IELTS Speaking test is a face-to-face interview assessing pronunciation, fluency, vocabulary, and coherence.
                            </p>
                            <h3 className="font-medium">Key Sections:</h3>
                            <ul className="list-disc list-inside text-gray-600">
                                <li>Part 1: Answer general questions about yourself and familiar topics (e.g., work, studies, hobbies).</li>
                                <li>Part 2: Speak about a specific topic for 1-2 minutes with a 1-minute preparation time.</li>
                                <li>Part 3: Engage in a discussion with the examiner on more abstract ideas related to Part 2.</li>
                            </ul>
                            <h3 className="font-medium">Preparation Tips:</h3>
                            <ul className="list-disc list-inside text-gray-600">
                                <li>Practice daily conversations on various topics to build comfort and fluency.</li>
                                <li>Structure responses with detailed answers using relevant examples.</li>
                                <li>Work on pronunciation and intonation to improve clarity and comprehension.</li>
                                <li>Record yourself speaking or practice with a partner for feedback.</li>
                            </ul>
                            <a
                                href={pdfLinks.speaking}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline"
                            >
                                Speaking PDF
                            </a>
                        </li>

                        {/* Grammar Module */}
                        <li>
                            <h2 className="text-lg font-semibold">IELTS Grammar Module</h2>
                            <p className="text-gray-700">
                                <strong>Overview:</strong> Grammar is essential in IELTS as it affects both your writing and speaking scores. A good command of grammar helps improve coherence, accuracy, and vocabulary range.
                            </p>
                            <h3 className="font-medium">Key Areas:</h3>
                            <ul className="list-disc list-inside text-gray-600">
                                <li>Tenses: Understand the correct usage of past, present, and future tenses in both written and spoken English.</li>
                                <li>Sentence Structures: Use complex and compound sentences for clarity and coherence.</li>
                                <li>Articles and Prepositions: Master the use of articles and prepositions to convey precise meanings.</li>
                                <li>Vocabulary: Expand your vocabulary with synonyms and collocations relevant to academic topics.</li>
                            </ul>
                            <h3 className="font-medium">Preparation Tips:</h3>
                            <ul className="list-disc list-inside text-gray-600">
                                <li>Practice sentence transformation exercises to use complex structures accurately.</li>
                                <li>Use grammar apps or websites to test your grammar knowledge and track progress.</li>
                                <li>Write daily journal entries in English to practice grammar in context.</li>
                                <li>Get feedback on your writing or speaking to identify and correct grammatical errors.</li>
                            </ul>
                            <a
                                href={pdfLinks.grammar}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline"
                            >
                                Grammar PDF
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default BegCoursePDF;
