import React from "react";
import photo1 from "./photos/photo1.jpg"; 
import photo2 from "./photos/photo2.jpg"; 
import photo3 from "./photos/photo3.jpg"; // Ensure file exists and path is accurate
import photo4 from "./photos/photo4.jpg"; // Same for this import
import photo5 from "./photos/photo5.jpg"; // And this one as well
import bannerImage from "./11454725.jpg";
import {Link} from 'react-router-dom'



function Speaking() {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center">
    {/* Main Content */}
    <main className="bg-white w-full max-w-5xl p-8 my-6 rounded-lg shadow-md">
      {/* Flex Container for Banner and Title */}
      <div className="flex items-center gap-5 mb-6">
        {/* Banner Image */}
        <img
          src={bannerImage}
          alt="Banner"
          className="w-60 h-60 object-cover mx-10 my-10 rounded-md"
        />

        {/* Title and Description */}
        <div>
          <h1 className="text-2xl font-bold">Quick Practice</h1>
          <p className="text-gray-700 mt-2">
            Improve specific skills by practicing mock questions types. Here, you
            will find a variety of test modules, including multiple-choice
            questions, fill-in-the-blanks, and interactive reading comprehension,
            designed to help you get accustomed to the IELTS test format.
          </p>
        </div>
      </div>

        {/* Buttons */}
        <div className="flex space-x-4 mb-8 justify-center gap-2
        ">
          <button className=" bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Book now
          </button>
          <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Practice free
          </button>
        </div>

        {/* Skill Tabs */}
        <div className="flex space-x-6 justify-center mb-8">
            <Link to={'/speaking'}>
          <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Speaking
          </button></Link>
          <Link to={'/reading'}>
          <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Reading
          </button></Link>
          <Link to={'/listening'}>
          <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Listening
          </button></Link>
          <Link to={'/writing'}>
          <button className="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-slate-300 hover:text-black">
            Writing
          </button></Link>
        </div>


        {/* Speaking Section */}
        <div className="mt-8">
  <h2 className="text-lg font-semibold mb-4">Speaking:</h2>
  <div className="grid grid-cols-2 gap-6">
    {/* First Speaking Section */}
    <div className="border p-4 rounded-lg shadow-sm flex flex-col items-center">
      <img src={photo1} alt="Read Aloud" className="w-20 h-20 mb-2" />
      <p className="font-semibold">Read Aloud</p>
    </div>

    {/* Second Speaking Section */}
    <div className="border p-4 rounded-lg shadow-sm flex flex-col items-center">
      <img src={photo2} alt="Speak About the Photo" className="w-20 h-20 mb-2" />
      <p className="font-semibold">Speak About the Photo</p>
    </div>

    {/* Third Speaking Section */}
    <div className="border p-4 rounded-lg shadow-sm flex flex-col items-center">
      <img src={photo3} alt="Read, Then Speak" className="w-20 h-20 mb-2" />
      <p className="font-semibold">Read, Then Speak</p>
    </div>

    {/* Fourth Speaking Section */}
    <div className="border p-4 rounded-lg shadow-sm flex flex-col items-center">
      <img src={photo4} alt="Listen, Then Speak" className="w-20 h-20 mb-2" />
      <p className="font-semibold">Listen, Then Speak</p>
    </div>

    {/* Fifth Speaking Section */}
    <div className="border p-4 rounded-lg shadow-sm  flex flex-col items-center">
      <img src={photo5} alt="Speaking Sample" className="w-20 h-20 mb-2" />
      <p className="font-semibold">Speaking Sample</p>
    </div>
  </div>
</div>


        {/* FAQ Section */}
        <div className="mt-8">
          <h2 className="text-lg font-semibold mb-4">Frequently Asked Questions (FAQs)</h2>
          <div className="space-y-4">
            <details className="border-b py-2">
              <summary className="font-medium">What is the IELTS Reading test?</summary>
              <p className="text-gray-600 mt-2">
                The IELTS Academic test includes various extracts from books, journals,
                and magazines, covering topics relevant to candidates. It assesses how
                well you understand the gist and details of the text.
              </p>
            </details>
            <details className="border-b py-2">
              <summary className="font-medium">How long is the IELTS Reading test?</summary>
              <p className="text-gray-600 mt-2">
                The test lasts for 60 minutes and consists of three long reading passages.
              </p>
            </details>
            <details className="border-b py-2">
              <summary className="font-medium">What types of questions are included in the test?</summary>
              <p className="text-gray-600 mt-2">
                Types include multiple-choice, identifying information, matching
                headings, and completing summaries.
              </p>
            </details>
            <details className="border-b py-2">
              <summary className="font-medium">Can I write on the question paper during the test?</summary>
              <p className="text-gray-600 mt-2">
                Yes, you are allowed to make notes, but only answers written on the
                answer sheet will be scored.
              </p>
            </details>
            <details className="border-b py-2">
              <summary className="font-medium">Are there any specific strategies for the IELTS Reading test?</summary>
              <p className="text-gray-600 mt-2">
                Familiarize yourself with the question types and practice scanning
                techniques.
              </p>
            </details>
            <details className="border-b py-2">
              <summary className="font-medium">Can I use a dictionary during the IELTS Reading test?</summary>
              <p className="text-gray-600 mt-2">
                No, the use of dictionaries is not permitted during the exam.
              </p>
            </details>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Speaking;
