import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const AdminRegister = () => {
  const [formData, setFormData] = useState({
    Mail: "",
    password: "",
    conformPassword: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const { Mail, password, conformPassword } = formData;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors

    // Check if passwords match
    if (password !== conformPassword) {
      setError("Passwords do not match!");
      toast.error("Passwords do not match!");
      return;
    }

    try {
      const response = await axios.post("https://ieltscoachingapi.whytap.tech/api/register-admin", formData);

      if (response.status === 200) {
        toast.success(response.data.message);
        navigate("/admin-login"); // Redirect to admin login page after successful registration
      }
    } catch (error) {
      setError(error.response ? error.response.data : "An error occurred");
      toast.error(error.response ? error.response.data : "An error occurred");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="grid lg:grid-cols-1 gap-6 w-full max-w-xl h-auto bg-white rounded-lg shadow-lg p-6">
        <div className="flex flex-col items-center justify-center space-y-4 w-full">
          <h1 className="text-2xl font-semibold text-center">Admin Registration</h1>
          {error && <h6 className="text-2xl font-semibold text-red-500 text-center">{error}</h6>}

          <form onSubmit={handleSubmit} className="w-3/4 flex flex-col gap-3">
            <input
              type="email"
              name="Mail"
              required
              placeholder="Admin Email"
              value={formData.Mail}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:ring-2 focus:ring-black"
            />
            <input
              type="password"
              name="password"
              required
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:ring-2 focus:ring-black"
            />
            <input
              type="password"
              name="conformPassword"
              required
              placeholder="Confirm Password"
              value={formData.conformPassword}
              onChange={handleChange}
              className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:ring-2 focus:ring-black"
            />
            <button
              type="submit"
              className="w-full inline-flex items-center justify-center rounded-lg bg-black py-3 text-sm font-medium text-white focus:ring-2 focus:ring-black"
            >
              Register Admin
            </button>
            <div className="flex justify-center items-center text-center text-sm text-slate-600">
            Have an account?
            <Link className="font-medium text-[#4285f4] ml-1" to={'/admin-login'}>Sign in</Link>
          </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminRegister;
