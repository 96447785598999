import React, { useEffect } from 'react';
import ss from "./Assets/ss.avif";
import write from "./Assets/write.avif";
import read from "./Assets/read.avif";
import speak from "./Assets/speak.avif";
import banner from "./Assets/banner.jpeg";

const FindMore = () => {
  useEffect(() => {
    window.scrollTo(0, 0);}, []);
  return (
    <div className="bg-gray-100 min-h-screen p-6 flex flex-col items-center">
      <div className="container mx-auto my-8 ">
        <h2 className="text-3xl font-semibold text-blue-700">Test Question Types</h2>
        <p className="mt-2 text-gray-700 text-2xl">
          Learn about the different types of questions you’ll see on the Duolingo English Test and see tips on how to respond.
        </p>
      </div>

      <div className="container mx-auto my-6">
        <img src={banner} alt="" className="w-full mx-auto rounded-lg" />
      </div>
      <div className="container mx-auto my-8">
        <h3 className="text-3xl font-semibold text-blue-700">General Tips</h3>
        <p className="text-gray-700 text-2xl">
          There are some good strategies and habits that apply to all of the question types on the Duolingo English Test.
        </p>
      </div>

      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
        <div className="bg-white p-4 rounded-lg shadow hover:shadow-lg text-center">
          <img src={ss} alt="Listening Tips" className="w-full mx-auto" />
          <h4 className="mt-4 font-semibold text-gray-800">Listening Tips</h4>
        </div>

        <div className="bg-white p-4 rounded-lg shadow hover:shadow-lg text-center">
          <img src={write} alt="Writing Tips" className="w-full mx-auto" />
          <h4 className="mt-4 font-semibold text-gray-800">Writing Tips</h4>
        </div>

        <div className="bg-white p-4 rounded-lg shadow hover:shadow-lg text-center">
          <img src={read} alt="Reading Tips" className="w-full mx-auto" />
          <h4 className="mt-4 font-semibold text-gray-800">Reading Tips</h4>
        </div>

        <div className="bg-white p-4 rounded-lg shadow hover:shadow-lg text-center">
          <img src={speak} alt="Speaking Tips" className="w-full mx-auto" />
          <h4 className="mt-4 font-semibold text-gray-800">Speaking Tips</h4>
        </div>
      </div>

      <div className="container mx-auto mt-8">
        <h2 className="text-3xl font-semibold text-blue-700">Writing Tips</h2>
        <p className="mt-2 text-gray-700 text-2xl">
          Use proper spelling, grammar, punctuation, and capitalization. The Duolingo English Test accepts both American and British spelling.
        </p>
        <p className="mt-2 text-gray-700 text-2xl">
          Vary your sentence structure and word choice as much as possible. Using more diverse, sophisticated vocabulary and grammar can help you achieve a better score.
        </p>
      </div>

      <div className="container mx-auto mt-8">
        <h2 className="text-3xl font-bold text-blue-700">Speaking Tips</h2>
        <p className="mt-4 text-gray-700 text-2xl">
          Speak loudly, clearly, and at an understandable pace. You will not have a chance to re-record your response.
        </p>
        <p className="mt-2 text-gray-700 text-2xl">
          Just like with writing, vary your sentence structure and word choice as much as possible.
        </p>
      </div>

      <div className="container mx-auto mt-8">
        <h2 className="text-3xl font-bold text-blue-700">Reading Tips</h2>
        <p className="mt-2 text-gray-700 text-2xl">
          Use proper spelling, grammar, punctuation, and capitalization. The Duolingo English Test accepts both American and British spelling.
        </p>
      </div>

      <div className="container mx-auto mt-8">
        <h2 className="text-3xl font-bold text-blue-700">Listening Tips</h2>
        <p className="mt-2 text-gray-700 text-2xl">
          Speak loudly, clearly, and at an understandable pace. Make sure you finish your entire response before submitting.
        </p>
      </div>

      <div className="container mx-auto mt-8">
        <h2 className="text-3xl font-bold text-blue-700">Rules for Test Questions</h2>
        <ul className="mt-2 text-gray-700 list-disc list-inside text-2xl">
          <li>Keep your eyes on the screen. You may look down at your keyboard to type answers during writing questions.</li>
          <li>Do not copy or memorize answers from other sources.</li>
          <li>Do not take notes.</li>
          <li>Do not allow anyone to assist you on the test.</li>
          <li>Do not speak until instructed to speak.</li>
          <li>Do not save or record test questions.</li>
        </ul>
      </div>
    </div>
  );
};



export default FindMore;
