import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProfilePage = () => {
  const [tests, setTests] = useState([]);

  useEffect(() => {
    const fetchTestHistory = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get("https://ieltscoachingapi.whytap.tech/api/test-history", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setTests(response.data);
      } catch (error) {
        console.error("Error fetching test history:", error);
      }
    };

    fetchTestHistory();
  }, []);

  return (
    <div className="container mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Your Test History</h2>

      {tests.length === 0 ? (
        <p>No tests taken yet.</p>
      ) : (
        <table className="min-w-full bg-white border">
          <thead>
            <tr className="bg-gray-100">
            <th className="py-3 px-4 border-b font-semibold text-gray-600 text-left">S.No</th>
              <th className="py-3 px-4 border-b font-semibold text-gray-600 text-left">Started At</th>
              <th className="py-3 px-4 border-b font-semibold text-gray-600 text-left">Status</th>
              <th className="py-3 px-4 border-b font-semibold text-gray-600 text-left">Type</th>
              <th className="py-3 px-4 border-b font-semibold text-gray-600 text-left">Score</th>
              <th className="py-3 px-4 border-b font-semibold text-gray-600 text-left">Percentage</th>
              <th className="py-3 px-4 border-b font-semibold text-gray-600 text-left">Completed At</th>
            </tr>
          </thead>
          <tbody>
            {tests.map((test, index) => (
              <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100`}>
               <td className="py-2 px-4 border-b">{index + 1}</td> {/* Serial Number */}
                <td className="py-2 px-4 border-b">{test.timestamp ? new Date(test.timestamp).toLocaleString() : "N/A"}</td>
                <td className={`py-2 px-4 border-b ${test.status === "pending" ? "text-red-500 font-medium" : "text-green-500 font-medium"}`}>
                  {test.status === "pending" ? "Pending" : "Completed"}
                </td>
                <td className="py-2 px-4 border-b">{test.type || "N/A"}</td>
                <td className="py-2 px-4 border-b">{test.score != null ? test.score : "N/A"}</td>
                <td className="py-2 px-4 border-b">{test.percentage != null ? `${test.percentage}%` : "N/A"}</td>
                <td className="py-2 px-4 border-b">{test.completedAt ? new Date(test.completedAt).toLocaleString() : "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ProfilePage;
