import React, { useState, useEffect } from 'react'; 
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

// const QuizComponent = () => {
//   const location = useLocation();
//   const [questionsData, setQuestionsData] = useState([]);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [userAnswers, setUserAnswers] = useState([]);
//   const [viewedAnswers, setViewedAnswers] = useState([]);
//   const [showAnswer, setShowAnswer] = useState(false);
//   const [score, setScore] = useState(0);
//   const [quizSubmitted, setQuizSubmitted] = useState(false);
//   const [timeLeft, setTimeLeft] = useState(30);
//   const [testId, setTestId] = useState(null); 
//   const [percentage, setPercentage] = useState(0);
//   const data = location.state;

//   const navigate = useNavigate();
//   const goToUserHistory = () => {
//     navigate('/user-history'); 
//   };

//   const goBack = () => {
//     navigate(-1); 
//   };

//   useEffect(() => {
//     const fetchQuestions = async () => {
//       const token = localStorage.getItem("token");
//       console.log(token);
      
//       try {
//         console.log("Sending request to start test...");

//         const response = await axios.post(
//           "https://ieltscoachingapi.whytap.tech/api/start-test",
//           data,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//               'Content-Type': 'application/json'
//             }
//           }
//         );
//         console.log("Response from backend:", response.data); 
//         setQuestionsData(response.data.questions.comprehensions);
//         setTestId(response.data.testId); 
//       } catch (error) {
//         console.error("Error fetching questions:", error.response?.data || error.message);
//       }
//     };
//     fetchQuestions();
//   }, [data]);

//   useEffect(() => {
//     if (timeLeft > 0 && !quizSubmitted) {
//       const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
//       return () => clearTimeout(timer);
//     } else if (timeLeft === 0 && !quizSubmitted) {
//       handleNextQuestion();
//     }
//   }, [timeLeft, quizSubmitted]);

//   const currentQuestion = questionsData[currentQuestionIndex];

//   const handleAnswerSelect = (selectedAnswer) => {
//     const newAnswers = [...userAnswers];
//     newAnswers[currentQuestionIndex] = selectedAnswer;
//     setUserAnswers(newAnswers);
//     setShowAnswer(false);
//   };

//   const handleShowAnswer = () => {
//     setShowAnswer(true);
//     setViewedAnswers([...viewedAnswers, currentQuestionIndex]);
//   };

//   const handleNextQuestion = () => {
//     if (currentQuestionIndex < questionsData.length - 1) {
//       setCurrentQuestionIndex(currentQuestionIndex + 1);
//       setShowAnswer(false);
//       setTimeLeft(30);
//     }
//   };

//   const handlePreviousQuestion = () => {
//     if (currentQuestionIndex > 0) {
//       setCurrentQuestionIndex(currentQuestionIndex - 1);
//       setShowAnswer(false);
//       setTimeLeft(30);
//     }
//   };

//   const handleSubmitQuiz = async () => {
//     let calculatedScore = 0;
//     questionsData.forEach((question, index) => {
//       if (userAnswers[index] === question.questions[0].answer && !viewedAnswers.includes(index)) {
//         calculatedScore += 1;
//       }
//     });
//     setScore(calculatedScore);
//     setQuizSubmitted(true);
//     const calculatedPercentage = ((calculatedScore / questionsData.length) * 100).toFixed(2);
//     setPercentage(calculatedPercentage); 
//     try {
//       const token = localStorage.getItem("token");
//       await axios.post(
//         "https://ieltscoachingapi.whytap.tech/api/submit-test" , 
//         {
//           testId,
//           type:data.type,
//           token,
//           answers: userAnswers,
//           score: calculatedScore,
//            percentage: calculatedPercentage
           
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );
//       console.log("Test results submitted successfully");
//     } catch (error) {
//       console.error("Error submitting test results:", error);
//     }
//   };

//   const handleGoToHome = () => {
//     navigate('/home');
//   };

//   if (questionsData.length === 0) {
//     return <div className="text-center text-xl mt-10">Loading questions...</div>;
//   }

//   return (
//     <div className="flex items-center justify-center min-h-screen bg-gray-100">
//       <div className="w-full max-w-3xl p-8 bg-white rounded-lg shadow-lg">
//         {quizSubmitted ? (
//           <div className="text-center">
//             <h2 className="text-3xl font-bold text-gray-800">Quiz Completed</h2>
//             <p className="text-lg mt-2">Your Score: {score} / {questionsData.length}</p>
//             <p className="text-md mt-1">You answered {percentage}% correctly.</p>
//             <button
//               onClick={handleGoToHome}
//               className="bg-cyan-700 text-white py-2 px-4 rounded-md mt-6"
//             >
//               Go to Home
//             </button>
//             <button
//                 onClick={goToUserHistory}
//                 className="bg-green-500 text-white py-2 px-4 rounded-md"
//               >
//                 User History
//               </button>
//               <button
//                 onClick={goBack}
//                 className="bg-gray-500 text-white py-2 px-4 rounded-md"
//               >
//                 Go Back
//               </button>
//           </div>
//         ) : (
//           <>
//             <div className="flex justify-between items-center mb-6">
//               <h2 className="text-2xl font-bold text-gray-800">Question {currentQuestionIndex + 1}</h2>
//               <p className="text-lg font-semibold text-red-600">
//                 Time Left: {timeLeft}s
//               </p>
//             </div>
//             <p className="text-md text-gray-700 mb-4"><strong>Passage:</strong> {currentQuestion.passage}</p>
//             <p className="text-lg font-semibold text-gray-800 mb-6"><strong>Question:</strong> {currentQuestion.questions[0].question}</p>

//             <div className="flex flex-col mb-6">
//               {currentQuestion.questions[0].options.map((option, index) => (
//                 <label key={index} className="text-md text-gray-700 mb-2 flex items-center">
//                   <input
//                     type="radio"
//                     value={option}
//                     checked={userAnswers[currentQuestionIndex] === option}
//                     onChange={() => handleAnswerSelect(option)}
//                     className="mr-2"
//                   />
//                   {option}
//                 </label>
//               ))}
//             </div>

//             <button 
//               onClick={handleShowAnswer} 
//               className="bg-green-500 text-white py-2 px-4 rounded-md mb-4 w-full"
//             >
//               Show Answer
//             </button>

//             {showAnswer && (
//               <p className="text-md text-green-600 font-semibold mb-4">
//                 <strong>Correct Answer:</strong> {currentQuestion.questions[0].answer}
//               </p>
//             )}

//             <div className="flex justify-between">
//               <button 
//                 onClick={handlePreviousQuestion} 
//                 className="bg-gray-500 text-white py-2 px-4 rounded-md"
//                 disabled={currentQuestionIndex === 0}
//               >
//                 Previous Question
//               </button>
              
//               {currentQuestionIndex < questionsData.length - 1 ? (
//                 <button 
//                   onClick={handleNextQuestion} 
//                   className="bg-blue-500 text-white py-2 px-4 rounded-md"
//                 >
//                   Next Question
//                 </button>
//               ) : (
//                 <button 
//                    onClick={handleSubmitQuiz} 
//                   className="bg-purple-500 text-white py-2 px-4 rounded-md"
//                 >
//                   Submit Quiz
//                 </button>
//               )}
//             </div>
//           </>
//         )}

//         <div className="mt-8">
//           <p className="text-md text-gray-600">Progress: {currentQuestionIndex + 1} / {questionsData.length}</p>
//           <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
//             <div
//               className="bg-blue-600 h-2.5 rounded-full"
//               style={{ width: `${((currentQuestionIndex + 1) / questionsData.length) * 100}%` }}
//             ></div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
// export default QuizComponent;
const QuizComponent = () => {
  const location = useLocation();
  const [questionsData, setQuestionsData] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [viewedAnswers, setViewedAnswers] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [score, setScore] = useState(0);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [testId, setTestId] = useState(null); 
  const [percentage, setPercentage] = useState(0);
  const data = location.state;

  const navigate = useNavigate();
  const goToUserHistory = () => {
    navigate('/user-history'); 
  };

  const goBack = () => {
    navigate(-1); 
  };

  // Fetch questions data
  useEffect(() => {
    const fetchQuestions = async () => {
      const token = localStorage.getItem("token");
      console.log(token);
      
      try {
        console.log("Sending request to start test...");

        const response = await axios.post(
          "https://ieltscoachingapi.whytap.tech/api/start-test",
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        console.log("Response from backend:", response.data); 
        setQuestionsData(response.data.questions);
        setTestId(response.data.testId); 
      } catch (error) {
        console.error("Error fetching questions:", error.response?.data || error.message);
      }
    };

    fetchQuestions();
  }, [data]);

  // Timer for quiz duration
  useEffect(() => {
    if (timeLeft > 0 && !quizSubmitted) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0 && !quizSubmitted) {
      handleNextQuestion();
    }
  }, [timeLeft, quizSubmitted]);

  // Guard clause for when no questions are fetched
  if (questionsData.length === 0) {
    return <div className="text-center text-xl mt-10">Loading questions...</div>;
  }

  const currentQuestion = questionsData[currentQuestionIndex];

  // Ensure we are dealing with a valid question and its options
  if (!currentQuestion || !currentQuestion.options || currentQuestion.options.length === 0) {
    return <div className="text-center text-xl mt-10">Invalid Question Data</div>;
  }

  const handleAnswerSelect = (selectedAnswer) => {
    const newAnswers = [...userAnswers];
    newAnswers[currentQuestionIndex] = selectedAnswer;
    setUserAnswers(newAnswers);
    setShowAnswer(false);
  };

  const handleShowAnswer = () => {
    setShowAnswer(true);
    setViewedAnswers([...viewedAnswers, currentQuestionIndex]);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questionsData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setShowAnswer(false);
      setTimeLeft(30);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setShowAnswer(false);
      setTimeLeft(30);
    }
  };

  const handleSubmitQuiz = async () => {
    let calculatedScore = 0;
    questionsData.forEach((question, index) => {
      if (userAnswers[index] === question.answer && !viewedAnswers.includes(index)) {
        calculatedScore += 1;
      }
    });
    setScore(calculatedScore);
    setQuizSubmitted(true);
    const calculatedPercentage = ((calculatedScore / questionsData.length) * 100).toFixed(2);
    setPercentage(calculatedPercentage); 
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        "https://ieltscoachingapi.whytap.tech/api/submit-test", 
        {
          testId,
          type: data.type,
          token,
          answers: userAnswers,
          score: calculatedScore,
          percentage: calculatedPercentage
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      console.log("Test results submitted successfully");
    } catch (error) {
      console.error("Error submitting test results:", error);
    }
  };

  const handleGoToHome = () => {
    navigate('/home');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-3xl p-8 bg-white rounded-lg shadow-lg">
        {quizSubmitted ? (
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-800">Quiz Completed</h2>
            <p className="text-lg mt-2">Your Score: {score} / {questionsData.length}</p>
            <p className="text-md mt-1">You answered {percentage}% correctly.</p>
            <button
              onClick={handleGoToHome}
              className="bg-cyan-700 text-white py-2 px-4 rounded-md mt-6"
            >
              Go to Home
            </button>
            <button
              onClick={goToUserHistory}
              className="bg-green-500 text-white py-2 px-4 rounded-md"
            >
              User History
            </button>
            <button
              onClick={goBack}
              className="bg-gray-500 text-white py-2 px-4 rounded-md"
            >
              Go Back
            </button>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-800">Question {currentQuestionIndex + 1}</h2>
              <p className="text-lg font-semibold text-red-600">
                Time Left: {timeLeft}s
              </p>
            </div>
            <p className="text-md text-gray-700 mb-4"><strong>Passage:</strong> {currentQuestion.passage}</p>
            <p className="text-lg font-semibold text-gray-800 mb-6"><strong>Question:</strong> {currentQuestion.question}</p>

            <div className="flex flex-col mb-6">
              {currentQuestion.options.map((option, index) => (
                <label key={index} className="text-md text-gray-700 mb-2 flex items-center">
                  <input
                    type="radio"
                    value={option}
                    checked={userAnswers[currentQuestionIndex] === option}
                    onChange={() => handleAnswerSelect(option)}
                    className="mr-2"
                  />
                  {option}
                </label>
              ))}
            </div>

            <button 
              onClick={handleShowAnswer} 
              className="bg-green-500 text-white py-2 px-4 rounded-md mb-4 w-full"
            >
              Show Answer
            </button>

            {showAnswer && (
              <p className="text-md text-green-600 font-semibold mb-4">
                <strong>Correct Answer:</strong> {currentQuestion.answer}
              </p>
            )}

            <div className="flex justify-between">
              <button 
                onClick={handlePreviousQuestion} 
                className="bg-gray-500 text-white py-2 px-4 rounded-md"
                disabled={currentQuestionIndex === 0}
              >
                Previous Question
              </button>
              
              {currentQuestionIndex < questionsData.length - 1 ? (
                <button 
                  onClick={handleNextQuestion} 
                  className="bg-blue-500 text-white py-2 px-4 rounded-md"
                >
                  Next Question
                </button>
              ) : (
                <button 
                  onClick={handleSubmitQuiz} 
                  className="bg-yellow-500 text-white py-2 px-4 rounded-md"
                >
                  Submit Quiz
                </button>
              )}
                         <p className="text-md text-gray-600">Progress: {currentQuestionIndex + 1} / {questionsData.length}</p>

            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default QuizComponent;


