import React, { useEffect } from 'react';

const Info = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-6 sm:px-12 lg:px-20">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-10 text-center">
          IELTS Information
        </h1>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-600">
            General Information
          </h2>
          <p className="text-gray-700 leading-relaxed">
            The International English Language Testing System (IELTS) is
            designed to assess the language ability of candidates who need to
            study or work where English is the language of communication. It
            measures listening, reading, writing, and speaking skills.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-600">
            Test Formats
          </h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-bold text-gray-800">Listening</h3>
              <p className="text-gray-700">
                <strong>Duration:</strong> 30 minutes
                <br />
                Includes four sections, each with 10 questions.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-800">Reading</h3>
              <p className="text-gray-700">
                <strong>Duration:</strong> 60 minutes
                <br />
                Contains three sections, with different texts and a total of 40
                questions.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-800">Writing</h3>
              <p className="text-gray-700">
                <strong>Duration:</strong> 60 minutes
                <br />
                Comprises two tasks: Task 1 (150 words) and Task 2 (250 words).
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-800">Speaking</h3>
              <p className="text-gray-700">
                <strong>Duration:</strong> 11–14 minutes
                <br />
                Consists of a face-to-face interview with an examiner, divided
                into three parts.
              </p>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-600">
            Preparation Tips
          </h2>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>Familiarize yourself with the test format.</li>
            <li>Practice your listening, reading, writing, and speaking skills regularly.</li>
            <li>Take practice tests under timed conditions.</li>
          </ul>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-4 text-indigo-600">
            Frequently Asked Questions (FAQs)
          </h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                What is the scoring system?
              </h3>
              <p className="text-gray-700">
                IELTS scores range from 1 to 9.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                How long is the IELTS test?
              </h3>
              <p className="text-gray-700">
                The total test time is 2 hours and 45 minutes.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                How often can I take the test?
              </h3>
              <p className="text-gray-700">
                You can take the IELTS test as many times as you wish.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Info;
