// import React, { useState } from 'react';
// import audio from './Audio/20241118004704-ielts-recent-actual-test-3-part-1.mp3';

// const ListeningTest = () => {
//   const [text, setText] = useState("");
//   const maxCharacters = 500;

//   const handleTextChange = (e) => {
//     setText(e.target.value);
//   };

//   const handleSubmit = () => {
//     alert("Your response has been submitted");
//     setText("");
//   };

//   return (
//     <div className='bg-gray-100 min-h-screen flex flex-col items-center p-6'>
//       <div className='bg-white w-full max-w-3xl p-6 rounded-lg shadow-md'>
//         <h1 className='text-2xl font-bold mb-4 text-center'>
//           Listening Test
//         </h1>
//         <div className='mb-6'>
//           <audio controls src={audio}>
//             Your browser does not support the audio element.
//           </audio>
//           <textarea
//             value={text}
//             onChange={handleTextChange}
//             className='w-full h-40 p-3 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 resize-none'
//             placeholder='Type your response here...'
//             maxLength={maxCharacters}
//           />
//         </div>
//         <div className='text-right text-gray-600 mt-2'>
//           Characters: {text.length}/{maxCharacters}
//         </div>
//         <button
//           onClick={handleSubmit}
//           disabled={text.length === 0}
//           className={`w-full mt-4 py-2 rounded-md font-semibold text-white ${
//             text.length === 0 ? "bg-gray-400 cursor-not-allowed" : "bg-purple-600 hover:bg-purple-950"
//           }`}
//         >
//           Submit
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ListeningTest;
import React, { useState } from 'react';
import audio from './Audio/20241118004704-ielts-recent-actual-test-3-part-1.mp3'; // Make sure this path is correct

const ListeningTest = () => {
  const [text, setText] = useState("");
  const maxCharacters = 500;

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = () => {
    alert("Your response has been submitted");
    setText("");
  };

  return (
    <div className='bg-gray-100 min-h-screen flex flex-col items-center p-6'>
      <div className='bg-white w-full max-w-3xl p-6 rounded-lg shadow-md'>
        <h1 className='text-2xl font-bold mb-4 text-center'>
          Listening Test
        </h1>

        {/* Check if the audio file is available */}
        <div className='mb-6'>
          {audio ? (
            <audio controls src={audio}>
              Your browser does not support the audio element.
            </audio>
          ) : (
            <p>Audio file not found.</p> // Fallback message if audio is missing
          )}

          <textarea
            value={text}
            onChange={handleTextChange}
            className='w-full h-40 p-3 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 resize-none'
            placeholder='Type your response here...'
            maxLength={maxCharacters}
          />
        </div>

        {/* Character count */}
        <div className='text-right text-gray-600 mt-2'>
          Characters: {text.length}/{maxCharacters}
        </div>

        {/* Submit button */}
        <button
          onClick={handleSubmit}
          disabled={text.length === 0}
          className={`w-full mt-4 py-2 rounded-md font-semibold text-white ${
            text.length === 0 ? "bg-gray-400 cursor-not-allowed" : "bg-purple-600 hover:bg-purple-950"
          }`}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ListeningTest;

