import React from 'react';
import { useNavigate } from 'react-router-dom';
import onlinetest from '../assests/onlinetest.png';

const ListenStartpg = () => {
  const navigate = useNavigate();

  const handleStart = () => {
    const datatype = {
      type: "listening",
    };
    navigate('/start-listentest', { state: datatype });
  };

  const handleCancel = () => {
    navigate('/home');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
        <h1 className="text-3xl  text-center font-bold mb-6 text-gray-800">Read and Complete</h1>
        <div className="mb-8">
          <img
            src={onlinetest}
            alt="Book illustration"
            className="w-full max-w-lg mx-auto rounded-md"
          />
          <p className="text-gray-600 mt-4 text-sm sm:text-base text-center">
            Read the passage and answer the questions in MCQ format.
          </p>
        </div>
        <div className="grid grid-cols-2 gap-6 mb-8 text-center">
          <div>
            <p className="text-gray-500 text-sm uppercase tracking-wide">
              Time per question
            </p>
            <p className="text-xl font-semibold text-gray-800">13 minutes</p>
          </div>
          <div>
            <p className="text-gray-500 text-sm uppercase tracking-wide">
              Questions
            </p>
            <p className="text-xl font-semibold text-gray-800">25</p>
          </div>
        </div>
        <div className="flex justify-between gap-4">
          <button
            onClick={handleCancel}
            className="flex-1 border border-blue-500 text-blue-500 rounded-md px-6 py-3 text-sm font-semibold hover:bg-blue-50 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleStart}
            className="flex-1 bg-indigo-600 text-white rounded-md px-6 py-3 text-sm font-semibold hover:bg-indigo-700 transition"
          >
            Start
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListenStartpg;
