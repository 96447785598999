import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="max-w-4xl mx-auto py-12 px-6 sm:px-8 lg:px-12 text-gray-800">
        <h1 className="text-4xl font-extrabold text-center text-indigo-600 mb-8">
          Privacy Policy
        </h1>
        <p className="text-center text-gray-600 mb-10">
          Your privacy is important to us. This policy explains how IELTS-Leetscholar collects, uses, and protects your personal information.
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
          <p>
            IELTS-Leetscholar ("we," "us," or "our") is committed to safeguarding your personal information. This Privacy Policy explains how we handle your data when you visit our website.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2>
          <ul className="list-disc list-inside space-y-4">
            <li>
              <strong>Personal Information:</strong> Information like your name, email address, and phone number that you provide through forms on our website.
            </li>
            <li>
              <strong>Usage Data:</strong> Data such as your IP address, device type, browser type, and pages visited during your session.
            </li>
            <li>
              <strong>Cookies:</strong> Small data files that enhance your experience and track your preferences.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
          <ul className="list-disc list-inside space-y-4">
            <li>To provide and improve our services</li>
            <li>To communicate updates and newsletters</li>
            <li>To respond to your inquiries</li>
            <li>To comply with legal requirements</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
          <p>
            We take reasonable precautions to secure your data. However, no method of transmission or storage is 100% secure.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Your Rights</h2>
          <p>You have the right to:</p>
          <ul className="list-disc list-inside space-y-4">
            <li>Access and receive a copy of your personal information</li>
            <li>Request rectification or deletion of your data</li>
            <li>Object to the processing of your data</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Contact Us</h2>
          <p>
            For inquiries about this policy, reach out at: 
            <a href="mailto:Ieltsleetscholar@gmail.com" className="text-blue-600 hover:underline">
              Ieltsleetscholar@gmail.com
            </a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
