    import React, { createContext, useEffect, useState } from 'react'
    export const AuthContext = createContext()
    const AuthProvider = ({ children }) => {
        const [isLoggedIn, setIsLoggedIn] = useState(false);
        const [isInitialized, setIsInitialized] = useState(false);
      
        useEffect(() => {
          const token = localStorage.getItem('token');
          setIsLoggedIn(!!token);
          setIsInitialized(true);
        }, []);
      
        const login = (token) => {
          localStorage.setItem('token', token);
          setIsLoggedIn(true);
        };
      
        const logout = () => {
          localStorage.removeItem('token');
          setIsLoggedIn(false);
        };
      
        if (!isInitialized) {
          return <div>Loading...</div>;
        }
      
        return (
          <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
          </AuthContext.Provider>
        );
      };
      
    export default AuthProvider



    // import React, { createContext, useEffect, useState } from 'react';
    // import axios from 'axios';
    
    // export const AuthContext = createContext();
    
    // const AuthProvider = ({ children }) => {
    //   const [isLoggedIn, setIsLoggedIn] = useState(false);
    //   const [role, setRole] = useState(null);  // Add a state for role (admin/user)
    //   const [isInitialized, setIsInitialized] = useState(false);
    
    //   useEffect(() => {
    //     const token = localStorage.getItem('token');
    //     const userRole = localStorage.getItem('role');  // Retrieve role from local storage
    
    //     if (token && userRole) {
    //       setIsLoggedIn(true);
    //       setRole(userRole);
    //     }
    
    //     setIsInitialized(true);
    //   }, []);
    
    //   const login = (token, role) => {
    //     localStorage.setItem('token', token);
    //     localStorage.setItem('role', role);  // Store role in local storage
    //     setIsLoggedIn(true);
    //     setRole(role);
    //   };
    
    //   const logout = () => {
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('role');
    //     setIsLoggedIn(false);
    //     setRole(null);
    //   };
    
    //   if (!isInitialized) {
    //     return <div>Loading...</div>;
    //   }
    
    //   return (
    //     <AuthContext.Provider value={{ isLoggedIn, role, login, logout }}>
    //       {children}
    //     </AuthContext.Provider>
    //   );
    // };
    
    // export default AuthProvider;
    



























































//     import React, { createContext, useEffect, useState } from 'react';
// import axios from 'axios';

// export const AuthContext = createContext();

// const AuthProvider = ({ children }) => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [isPaidUser, setIsPaidUser] = useState(false);
//   const [isInitialized, setIsInitialized] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     const userId = localStorage.getItem('userId');

//     if (token && userId) {
//       setIsLoggedIn(true);

//       // Fetch user's payment status
//       axios
//         .get(`/api/users/user/status/${userId}`, {
//           headers: { Authorization: `Bearer ${token}` }, // Pass the token as Authorization header
//         })
//         .then((response) => {
//           setIsPaidUser(response.data.isPaid); // Update the payment status
//           setIsInitialized(true); // Mark the initialization as complete
//         })
//         .catch((error) => {
//           console.error('Error fetching payment status:', error);
//           setIsInitialized(true); // Handle error and allow rendering
//         });
//     } else {
//       setIsInitialized(true); // No token, skip user verification
//     }
//   }, []);

//   const login = (token, userId) => {
//     localStorage.setItem('token', token);
//     localStorage.setItem('userId', userId);
//     setIsLoggedIn(true);
//   };

//   const logout = () => {
//     localStorage.removeItem('token');
//     localStorage.removeItem('userId');
//     setIsLoggedIn(false);
//     setIsPaidUser(false); // Reset payment status on logout
//   };

//   if (!isInitialized) {
//     return <div>Loading...</div>; // Show loading while fetching user data
//   }

//   return (
//     <AuthContext.Provider value={{ isLoggedIn, isPaidUser, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthProvider;
